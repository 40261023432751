import React from "react";
import i18n from "i18next";
const CostsFieldsInTask = [
  {
    type: "refrence",
    label: i18n.t("events.id"),
    id: "id",
    onlyLink: true,
    show_list: false,
  },
  {
    type: "text",
    label: i18n.t("costs.title"),
    id: "title",
    show_list: true,
  },
  {
    type: "select",
    label: i18n.t("costs.type"),
    id: "type",
    noneExist: true,
    show_list: true,
    data: [
      { value: "task_cost", text: i18n.t("costs.types.task_cost") },
      { value: "worker_cost", text: i18n.t("costs.types.worker_cost") },
      // { value: "office_cost", text: i18n.t("costs.types.office_cost") },
      // { value: "employees_cost", text: i18n.t("costs.types.employees_cost") },
      { value: "other", text: i18n.t("costs.types.other") },
    ],
  },
  {
    type: "price",
    label: i18n.t("costs.amount"),
    id: "amount",
    customType: "number",
    show_list: true,
  },
  {
    type: "datepicker",
    label: i18n.t("costs.paid_date"),
    id: "paid_date",
    show_list: true,
  },
  {
    type: "text",
    linked: true,
    label: "Task",
    noneExist: false,
    id: "task",
    fetch: true,
    disabled: true,
    show_field: ["name"],
    controller: "tasks",
    searchable: false,
    show_view: true,
  },
  {
    type: "select",
    // linked: true,
    noneExist: true,
    label: "Worker Name",
    id: "worker",
    fetch: true,
    show_field: ["first_name", "last_name"],
    controller: "worker",
    show_list: true,
    show_view: true,
  },
  {
    type: "select",
    label: i18n.t("costs.status"),
    id: "status",
    noneExist: true,
    show_list: true,
    data: [
      { value: "paid", text: i18n.t("costs.status_list.paid") },
      { value: "unpaid", text: i18n.t("costs.status_list.unpaid") },
      { value: "cancelled", text: i18n.t("costs.status_list.cancelled") },
    ],
  },
  {
    type: "text",
    label: i18n.t("costs.description"),
    id: "description",
    show_list: false,
    show_view: true,
  },
];
const CostsFields = [
  {
    type: "refrence",
    label: i18n.t("events.id"),
    id: "id",
    onlyLink: true,
    show_list: false,
  },
  {
    type: "text",
    label: i18n.t("costs.title"),
    id: "title",
    show_list: true,
  },
  {
    type: "select",
    label: i18n.t("costs.type"),
    id: "type",
    noneExist: false,
    show_list: true,
    data: [
      // { value: "task_cost", text: i18n.t("costs.types.task_cost") },
      // { value: "worker_cost", text: i18n.t("costs.types.worker_cost") },
      { value: "office_cost", text: i18n.t("costs.types.office_cost") },
      { value: "employees_cost", text: i18n.t("costs.types.employees_cost") },
      { value: "other", text: i18n.t("costs.types.other") },
    ],
  },
  {
    type: "price",
    label: i18n.t("costs.amount"),
    id: "amount",
    customType: "number",
    show_list: true,
  },
  // {
  //   type: "datepicker",
  //   label: i18n.t("costs.paid_date"),
  //   id: "paid_date",
  //   show_list: true,
  // },
  // {
  //   type: "select",
  //   linked: true,
  //   label: "Task",
  //   noneExist: true,
  //   id: "task",
  //   fetch: true,
  //   show_field: ["name"],
  //   controller: "tasks",
  //   searchable: false,
  //   show_view: true,
  // },
  // {
  //   type: "select",
  //   // linked: true,
  //   noneExist: true,
  //   label: "Worker Name",
  //   id: "worker",
  //   fetch: true,
  //   show_field: ["first_name", "last_name"],
  //   controller: "worker",
  //   show_list: true,
  //   show_view: true,
  // },
  {
    type: "select",
    label: i18n.t("costs.status"),
    id: "status",
    noneExist: true,
    show_list: true,
    data: [
      { value: "paid", text: i18n.t("costs.status_list.paid") },
      { value: "unpaid", text: i18n.t("costs.status_list.unpaid") },
      { value: "cancelled", text: i18n.t("costs.status_list.cancelled") },
    ],
  },
  {
    type: "text",
    label: i18n.t("costs.description"),
    id: "description",
    show_list: false,
    show_view: true,
  },
];
export { CostsFields, CostsFieldsInTask };
