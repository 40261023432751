import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl } from "../../components/Forms";
// import { TasksFields } from "../../context/Forms";
import { TasksSteps, TasksFields } from "./steps";
import { refreshNew } from "../../redux/features/tasks";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Button, Grid } from "@material-ui/core";
import { FormStepper } from "../../components/Forms";
import { useDispatch, useSelector } from "react-redux";
import { task } from "../../redux/store";
import { add } from "../../redux/features/tasks";
import { api } from "../../services/api";
import toast, { Toaster } from "react-hot-toast";
// import { dispatch } from "react-hot-toast/dist/core/store";
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
  cellWidth: { width: "30%" },
});

const CreateTask = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const new_task = useSelector((state) => state.task.new);
  const fetch = (e) => {
    api.create({ controller: "tasks", data: e }).then((res) => {
      dispatch(refreshNew());
      toast.success(t("events.created"));
    });
  };
  return (
    <FormStepper
      onFinished={(e) => fetch(new_task)}
      sendAfterComplete
      stepsData={TasksSteps}
      singleForm
      fields={TasksFields}
    />
  );
};
export default CreateTask;
