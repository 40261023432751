import React, { useState } from "react";
import {
  styled,
  Dialog,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Stack,
} from "@mui/material";
import { Grid } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useTranslation } from "react-i18next";
import {
  InputRadio,
  InputText,
  InputSelect,
} from "../../../../components/Forms";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  add_destination,
  delete_destination,
} from "../../../../redux/features/tasks";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  width: "auto",
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const ItemRender = ({ label, title, children }) => {
  if (!children) return <React.Fragment></React.Fragment>;
  return (
    <Item>
      {label}: {children}
    </Item>
  );
};
const truefalse = ["Ja", "Nein"];
const possibility_to_attach_warning_signs_colors = [
  "disabled",
  "warning",
  "error",
  "success",
  "secondary",
  "primary",
];
const DestinationLocationComp = ({
  handleClose,
  handleSave,
  handleClickOpen,
}) => {
  const { t } = useTranslation();
  const [address, setAddress] = useState("");
  const [destinationFields, setdestinationFields] = useState({});
  const onChangeDestinationField = (e) => {
    setdestinationFields((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };
  const onChangeAddress = (e) => {
    setAddress(e.label);
  };
  return (
    <React.Fragment>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              handleClose();
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {t("destination_location.add_new_destination")}
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={() => {
              handleSave({ ...destinationFields, address: address });
            }}
          >
            {t("destination_location.save")}
          </Button>
        </Toolbar>
      </AppBar>
      <br />
      <br />
      <Grid container>
        <Grid item xs={10} lg={10} sm={10} md={10}>
          <Grid container>
            <Grid
              item
              style={{ zIndex: 2, marginLeft: 10 }}
              xs={12}
              sm={12}
              lg={12}
              md={12}
            >
              <GooglePlacesAutocomplete
                apiKey="AIzaSyDUb_he0UVZUDzi0LPT8zrTDi1G34DVKLk"
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ["at", "br", "de", "ch", "dk", "it", "nl", "se"],
                  },
                }}
                selectProps={{
                  address,
                  onChange: onChangeAddress,
                  styles: {
                    input: (provided) => ({
                      ...provided,
                      height: "100%",
                      color: "blue",
                    }),
                    option: (provided) => ({ ...provided, color: "blue" }),
                    singleValue: (provided) => ({ ...provided, color: "blue" }),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={4} md={4}>
              <InputText
                label={t("destination_location.hnr")}
                onChange={onChangeDestinationField}
                id="destination_hnr"
                value={destinationFields.destination_hnr || ""}
              />
            </Grid>
            <Grid item xs={6} sm={4} lg={4} md={4}>
              <InputText
                label={t("destination_location.siege")}
                onChange={onChangeDestinationField}
                id="destination_siege"
                value={destinationFields.destination_siege}
              />
            </Grid>
            <Grid item xs={6} sm={4} lg={4} md={4}>
              <InputText
                label={t("destination_location.tur_nr")}
                onChange={onChangeDestinationField}
                id="destination_tur_nr"
                value={destinationFields.destination_tur_nr}
              />
            </Grid>
            <Grid container>
              <Grid item xs={3} sm={3} lg={3} md={3}>
                <InputText
                  label={t("destination_location.plz")}
                  onChange={onChangeDestinationField}
                  id="destination_postcode"
                  value={destinationFields.destination_postcode}
                />
              </Grid>
              <Grid item xs={6} sm={3} lg={6} md={6}>
                <InputText
                  label={t("destination_location.bundesland")}
                  onChange={onChangeDestinationField}
                  id="destination_city"
                  value={destinationFields.destination_city}
                />
              </Grid>
              <Grid item xs={3} sm={3} lg={3} md={3}>
                <InputText
                  label={t("destination_location.stock")}
                  onChange={onChangeDestinationField}
                  id="destination_stock"
                  value={destinationFields.destination_stock}
                />
              </Grid>
              <Grid item xs={6} sm={5} lg={5} md={5}>
                <InputText
                  label={t("destination_location.floor")}
                  onChange={onChangeDestinationField}
                  id="destination_floor"
                  value={destinationFields.destination_floor}
                />
              </Grid>
              <Grid item xs={6} sm={5} lg={5} md={5}>
                <InputText
                  label={t("destination_location.plaque")}
                  onChange={onChangeDestinationField}
                  id="destination_plaque"
                  value={destinationFields.destination_plaque}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <InputRadio
                  label={t("destination_location.lift")}
                  id="destination_lift"
                  onChange={onChangeDestinationField}
                  value={destinationFields.destination_lift}
                  data={[
                    { value: 1, label: t("destination_location.ja") },
                    { value: 2, label: t("destination_location.nein") },
                  ]}
                />
              </Grid>
            </Grid>
            <Grid container>
              {destinationFields.destination_lift == 1 && (
                <Grid item xs={12} sm={4} lg={6} md={6}>
                  <InputText
                    label={t("destination_location.lift_load")}
                    id={"destination_lift_load"}
                    onChange={onChangeDestinationField}
                    value={destinationFields.destination_lift_load}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sm={12}
                lg={destinationFields.destination_lift == 1 ? 6 : 12}
                md={destinationFields.destination_lift == 1 ? 6 : 12}
              >
                <InputText
                  label={t("destination_location.apartment_area")}
                  onChange={onChangeDestinationField}
                  value={destinationFields.destination_apartment_area}
                  id="destination_apartment_area"
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <InputRadio
                  label={t("destination_location.access_to_parking")}
                  id="destination_access_to_parking"
                  onChange={onChangeDestinationField}
                  value={destinationFields.destination_access_to_parking}
                  data={[
                    { value: 1, label: t("destination_location.ja") },
                    { value: 2, label: t("destination_location.nein") },
                  ]}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <InputText
                  label={"Distance from parking to home"}
                  id="destination_distance_parking_to_home"
                  onChange={onChangeDestinationField}
                  value={destinationFields.destination_distance_parking_to_home}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <InputSelect
                  noneExist
                  label={t(
                    "destination_location.possibility_to_attach_warning_signs"
                  )}
                  id="possibility_to_attach_warning_signs"
                  onChange={onChangeDestinationField}
                  value={destinationFields.possibility_to_attach_warning_signs}
                  data={[
                    {
                      value: 1,
                      text: t(
                        "tasks.origin_location.possibility_to_attach_warning_signs_list.no_need"
                      ),
                    },
                    {
                      value: 2,
                      text: t(
                        "tasks.origin_location.possibility_to_attach_warning_signs_list.it_must"
                      ),
                    },
                    {
                      value: 3,
                      text: t(
                        "tasks.origin_location.possibility_to_attach_warning_signs_list.requested"
                      ),
                    },
                    {
                      value: 4,
                      text: t(
                        "tasks.origin_location.possibility_to_attach_warning_signs_list.request_accepted"
                      ),
                    },
                  ]}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={4} lg={4} md={4}>
                <InputText
                  label={t("global_fields.total_truck")}
                  onChange={onChangeDestinationField}
                  id="total_truck"
                  value={destinationFields.total_truck}
                />
              </Grid>
              <Grid item xs={11} sm={4} md={4} lg={4}>
                <InputText
                  label={t("global_fields.people")}
                  onChange={onChangeDestinationField}
                  id="people"
                  value={destinationFields.people}
                />
              </Grid>
              <Grid item xs={11} sm={4} md={4} lg={4}>
                <InputText
                  label={t("global_fields.transportation")}
                  onChange={onChangeDestinationField}
                  id="transportation"
                  value={destinationFields.transportation}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <InputRadio
                  label={t(
                    "destination_location.what_is_the_number_of_furniture_over_80_kg"
                  )}
                  id="destination_more_than_80_furniture"
                  onChange={onChangeDestinationField}
                  value={destinationFields.destination_more_than_80_furniture}
                  data={[
                    { value: 1, label: t("destination_location.ja") },
                    { value: 2, label: t("destination_location.nein") },
                  ]}
                />
              </Grid>
            </Grid>
            {destinationFields.destination_more_than_80_furniture == 1 && (
              <Grid container>
                <Grid item xs={12} sm={12} lg={12} md={12}>
                  <InputText
                    label={t(
                      "destination_location.what_is_the_number_of_furniture_over_80_kg"
                    )}
                    id="destination_number_of_furniture_over_80"
                    onChange={onChangeDestinationField}
                    value={
                      destinationFields.destination_number_of_furniture_over_80
                    }
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
const RenderDestinationComp = () => {
  const { t } = useTranslation();
  const destinationList = useSelector((state) => state.task.new.destination);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSave = (e) => {
    dispatch(add_destination(e));
    setOpen(false);
  };
  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{t("destination_location.address")}</TableCell>
              <TableCell align="right">
                {t("destination_location.hnr")}
              </TableCell>
              <TableCell align="right">
                {t("destination_location.bundesland")}
              </TableCell>
              <TableCell align="right">
                {t("destination_location.stock")}
              </TableCell>
              <TableCell align="right">
                {t("destination_location.action")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {destinationList.map((row) => (
              <Row key={row.address} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button variant="outlined" onClick={handleClickOpen}>
        {t("destination_location.add_new_address")}
      </Button>
      <Dialog
        fullScreen
        open={open}
        // handlesave={handleSave}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
      >
        <DestinationLocationComp
          handleClickOpen={handleClickOpen}
          handleClose={() => setOpen(false)}
          handleSave={handleSave}
        />
      </Dialog>
    </div>
  );
};
function Row(props) {
  const { t } = useTranslation();
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <WarningAmberIcon
            color={
              parseInt(row.possibility_to_attach_warning_signs) === 1
                ? "success"
                : "disabled"
            }
          />
          {row.address}
        </TableCell>
        <TableCell align="right">{row.destination_hnr}</TableCell>
        <TableCell align="right">{row.destination_city}</TableCell>
        <TableCell align="right">{row.destination_stock}</TableCell>
        <TableCell align="right">
          <IconButton
            onClick={(e) => dispatch(delete_destination({ id: row.id }))}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12} sm={12}>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <ItemRender label={t("destination_location.siege")}>
                      {row.destination_siege}
                    </ItemRender>
                    <ItemRender label={t("destination_location.tur_nr")}>
                      {row.destination_tur_nr}
                    </ItemRender>
                    <ItemRender label={t("destination_location.plz")}>
                      {row.destination_postcode}
                    </ItemRender>
                    <ItemRender label="Lift">{row.destination_lift}</ItemRender>
                    <ItemRender
                      label={t("destination_location.apartment_area")}
                    >
                      {row.destination_apartment_area}
                    </ItemRender>
                    <ItemRender
                      label={t("destination_location.access_to_parking")}
                    >
                      {truefalse[row.destination_access_to_parking - 1]}
                    </ItemRender>
                    <ItemRender
                      label={t(
                        "destination_location.distance_from_parking_to_home"
                      )}
                    >
                      {row.destination_distance_parking_to_home}
                    </ItemRender>
                    <ItemRender label={t("global_fields.total_truck")}>
                      {row.total_truck}
                    </ItemRender>
                    {parseInt(row.destination_more_than_80_furniture) === 1 && (
                      <ItemRender
                        label={t(
                          "destination_location.what_is_the_number_of_furniture_over_80_kg"
                        )}
                      >
                        {row.destination_number_of_furniture_over_80}
                      </ItemRender>
                    )}
                    {parseInt(row.possibility_to_attach_warning_signs) && (
                      <ItemRender
                        label={t(
                          "destination_location.possibility_to_attach_warning_signs"
                        )}
                      >
                        <WarningAmberIcon
                          color={
                            possibility_to_attach_warning_signs_colors[
                              parseInt(
                                row.possibility_to_attach_warning_signs - 1
                              )
                            ]
                          }
                        />{" "}
                      </ItemRender>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
export default RenderDestinationComp;
