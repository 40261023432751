import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
// import 'moment/locale/fa';

// import EditOne from './EditOne';
import { api } from '../../services/api';
import { Box } from './StyledComponents/BoxComponent';
import GridHandler from '../../components/GridView/GridHandler';
import ValueRenderer from '../../components/ValueRenderer/ValueRenderer';
import ViewOneLoading from './ViewOneLoading';

const FieldContent = ({
    autoTranslation,
    fields,
    field_key,
    field_value,
}) => {
    const { t } = useTranslation();

    if (
        fields.filter((item) => item.id === field_key).length > 0 &&
        fields.filter(
            (item) =>
                (item.id === field_key && item.show_list) ||
                (item.id === field_key && item.show_view),
        ).length > 0
    ) {
        return (
            <Grid container>
                <Grid item>
                    {autoTranslation &&
                    fields.filter(
                        (item) =>
                            item.id === field_key && (item.show_list || item.show_view)
                    ).length > 0
                        ? t(`global_fields.${field_key}`)
                        : fields
                              .filter((item) => (item.show_list || item.show_view))
                              .filter(
                                  (field) => field.id === field_key,
                              ).length > 0 && field_key !== 'id'
                        ? fields
                              .filter((item) => (item.show_list || item.show_view))
                              .filter(
                                  (field) => field.id === field_key,
                              )[0].label
                        : fields
                              .filter((item) => (item.show_list || item.show_view))
                              .filter(
                                  (item) => item.id === field_key,
                              )}
                    :&nbsp;
                </Grid>
                <Grid item>
                    <ValueRenderer
                        fields={fields}
                        field_key={field_key}
                        field_value={field_value}
                    />
                </Grid>
            </Grid>
        );
    }
    return <React.Fragment>{JSON.stringify(fields.filter((item) => item.id === field_key).length)}</React.Fragment>;
};

const ViewOne = ({
    autoTranslation,
    controller,
    localData,
    autoRoute,
    fields,
    select,
    title,
    id,
    footerContent,
    manualWithController,
    autoRefresh
}) => {
    const [edit, setEdit] = useState(false);
    const [data, setData] = useState();
    const [refresh,setRefresh] = useState(autoRefresh)
    const editForm = () => {
        setEdit(true);
    };
    useEffect(() => {
        if (manualWithController || autoRefresh || (autoRoute && !localData)) {
            api.getOne({
                controller: controller,
                id: id,
                select: fields.map((item) => item.id),
            }).then((res) => {
                res.data &&
                    setTimeout(() => {
                        setData(res.data);
                        setRefresh(false)
                    }, 300);
            });
        } else {
            setData(localData);
        }
    }, [edit,autoRefresh,localData]);
    if (edit)
        return (
            <React.Fragment>
                {/* {data ? (
                    <EditOne
                        autoTranslation={autoTranslation}
                        controller={controller}
                        localData={localData}
                        setEdit={setEdit}
                        fields={fields}
                        title={data['title']}
                        data={data}
                        edit={edit}
                        id={id}
                    />
                ) : (
                    <ViewOneLoading />
                )} */}
            </React.Fragment>
        );
    else {
        return (
            <React.Fragment>
                {data ? (
                    <Box
                        component={Link}
                        to={'#'}
                        subTitle={true}
                        onClickEdit={editForm}
                        key={data.id}
                        title={data[title]}
                        grid={{ xs: 12, md: 12, lg: 12 }}
                        edit={edit}
                    >
                        <Grid container>
                            {Object.entries(data).filter(item=>item[1])
                                .filter((fld_itm) =>
                                    fields.filter(item=>item.id!=="id")
                                        .map((fld) => fld.id)
                                        .includes(fld_itm[0]),
                                )
                                .map((vals, key) => (
                                    <Grid
                                        item
                                        key={key}
                                        xs={GridHandler({
                                            fields: fields,
                                            field: vals[0],
                                            type: 'xs',
                                        })}
                                        md={GridHandler({
                                            fields: fields,
                                            field: vals[0],
                                            type: 'md',
                                        })}
                                        lg={GridHandler({
                                            fields: fields,
                                            field: vals[0],
                                            type: 'lg',
                                        })}
                                        sm={GridHandler({
                                            fields: fields,
                                            field: vals[0],
                                            type: 'sm',
                                        })}
                                    >
                                        <FieldContent
                                            autoTranslation={
                                                autoTranslation
                                            }
                                            field_value={vals[1]}
                                            field_key={vals[0]}
                                            fields={fields}
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                        {footerContent && footerContent(data)}
                    </Box>
                ) : (
                    <ViewOneLoading />
                )}
            </React.Fragment>
        );
    }
};
export default ViewOne;
