import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console.
var firebaseConfig = {
  //This config will be specific to yours
  apiKey: "AIzaSyDUb_he0UVZUDzi0LPT8zrTDi1G34DVKLk",
  authDomain: "dashboard-umzugsritter.firebaseapp.com",
  databaseURL: "https://yourdb.com",
  projectId: "dashboard-umzugsritter",
  storageBucket: "dashboard-umzugsritter.appspot.com",
  messagingSenderId: "48324385484",
  appId: "1:48324385484:web:20c1e6095722ab7397c069",
  measurementId: "G-C964TGTBPT",
};
initializeApp(firebaseConfig);
const messaging = getMessaging();
export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BM3qbICneVyACeEd0JReLj3C53Yv-uPGLEELCi0s6V2jxNdXv4qU0Q3G5htlvLKFc1URqLthtN1HDhDK-rkMmaA",
  })
    .then((currentToken) => {
      if (currentToken) {
        localStorage.setItem("firebase", currentToken);
        return currentToken;
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};
