import React,{useEffect,useState} from "react";
import { api } from "../../../services/api";
import { GridTablePagination } from "../";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const GridTableFooter = ({controller,onChangePage,page}) => {
  const [data,setData] = useState()
  const [totalPage,setTotalPage] = useState()
  const [loading,setLoading] = useState(true)
  useEffect(()=>{
    api.pagination({controller})
    .then((res)=>{
      setData(res.data);
      const pages = (res.data.total/10)
      if(pages!==pages.toFixed(0)){
        setTotalPage(parseInt(parseInt(pages.toString().split(".")[0])+1))
        setLoading(false)
      }else{
        setTotalPage(parseInt(pages))
        setLoading(false)
      }
      // if(pages > pages.toFixed(0)){
      //   setTotalPage(Math.round(pages)+1)
      //   setLoading(false);
      // }else{
      //   console.log(parseInt(pages))
      //   if(parseInt(pages)<1) setTotalPage(1)
      //   setTotalPage(parseInt(pages))
      //   setLoading(false);
      // }
      // console.log(totalPage)
    })
  },[])

  return (
    <Stack spacing={2}>
      {!loading && (<Pagination count={totalPage} onChange={(e,pg)=>onChangePage(pg)} variant="outlined" shape="rounded" />)}
    </Stack>
  );
};

export { GridTableFooter };
