import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
// import { TasksFields } from "../../context/Forms";
import { CardHeader } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  ContractType,
  MeetingTimeComp,
  OriginLocationComp,
  DestinationLocationComp,
  WorkersComp,
  ServicesComp,
  InventoryComp,
  CostsComp,
} from "./components/Form";
import { Fab } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addView } from "../../redux/features/tasks";
import { api } from "../../services/api";

const TasksView = (prop) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const viewTasks = useSelector((state) => state.task.view);
  const [expanded, setExpanded] = React.useState("contract");
  const [globalEditable, setGlobalEditable] = React.useState(false);
  const viewUser = useSelector((state) => state.user.value);
  const [workerData, setWorkerData] = useState({});
  const [sendReview, setSendReview] = useState(0);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    api
      .getOne({ controller: "tasks/view", id: prop.match.params.id })
      .then((res) => {
        console.log(res.data);
        dispatch(addView({ id: prop.match.params.id, data: res.data }));
      });
  }, []);
  useEffect(() => {
    if (
      viewTasks.length > 0 &&
      viewTasks[0].workers_id.filter((item) => item.id == viewUser.user_id)
        .length > 0
    ) {
      setWorkerData(
        viewTasks[0].workers_id.filter((item) => item.id == viewUser.user_id)[0]
      );
    }
  }, [viewTasks]);

  const fetch = () => {};
  const onChangeReview = (review) => {
    setSendReview(review);
  };
  const onSave = () => {
    new Promise((resolve, reject) => {
      let updateData = viewTasks.filter(
        (item) => item.id == prop.match.params.id
      )[0];
      api.updateOne(
        {
          controller: "tasks",
          id: prop.match.params.id,
          data: { ...updateData },
        },
        (res) => {
          setGlobalEditable(false);
          resolve("Success!");
        }
      );
    });
  };

  return (
    <div>
      <Fab
        aria-label={"Add"}
        color={"red"}
        style={{
          position: "fixed",
          zIndex: 99999,
          bottom: 14,
          background: globalEditable ? "greenyellow" : "yellow",
        }}
        onClick={() => (!globalEditable ? setGlobalEditable(true) : onSave())}
      >
        {globalEditable ? "Save" : <EditIcon />}
      </Fab>
      <Grid container spacing={4} style={{ paddingBottom: 14 }}>
        <CardHeader
          avatar={
            <img
              width={48}
              height={48}
              src={
                viewTasks.filter((item) => item.id === prop.match.params.id)
                  .length > 0 &&
                viewTasks.filter((item) => item.id === prop.match.params.id)[0]
                  .company == 2
                  ? "https://dashboard.umzugsritter.at/assets/herzog.png"
                  : "https://dashboard.umzugsritter.at/assets/logo.jpg"
              }
            />
          }
          title={
            viewTasks.filter((item) => item.id === prop.match.params.id)
              .length > 0 &&
            viewTasks.filter((item) => item.id === prop.match.params.id)[0]
              .company == 2
              ? "Herzog Umzug"
              : "UmzugsRitter"
          }
          subheader={
            viewTasks.filter((item) => item.id === prop.match.params.id)
              .length > 0 &&
            viewTasks.filter((item) => item.id === prop.match.params.id)[0]
              .umzugsplan_id
          }
        />
        {/* <CardHeader
          avatar={
            <Avatar
              sx={{ bgcolor: red[500] }}

            />
          }
          action={
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          }
          title={
            viewTasks.filter((item) => item.id === prop.match.params.id)
              .length > 0 &&
            viewTasks.filter((item) => item.id === prop.match.params.id)[0]
              .company == 2
              ? "Herzog Umzug"
              : "UmzugsRitter"
          }
          subheader={
            viewTasks.filter((item) => item.id === prop.match.params.id)
              .length > 0 &&
            viewTasks.filter((item) => item.id === prop.match.params.id)[0]
              .umzugsplan_id
          }
        /> */}
        <Grid item>
          <Button></Button>
        </Grid>
      </Grid>
      <Accordion
        expanded={expanded === "contract"}
        onChange={handleChange("contract")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            {t("view_task.contract_info")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ContractType
            globalEditable={globalEditable}
            sendReview={onChangeReview}
            review={sendReview}
            onSave={onSave}
            {...prop}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "meeting"}
        onChange={handleChange("meeting")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            {t("view_task.meeting_date")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MeetingTimeComp
            globalEditable={globalEditable}
            onSave={onSave}
            {...prop}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "origin"}
        onChange={handleChange("origin")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            {t("view_task.origin_location")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <OriginLocationComp
            globalEditable={globalEditable}
            onSave={onSave}
            {...prop}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "destination"}
        onChange={handleChange("destination")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            {t("view_task.destination_info")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DestinationLocationComp
            globalEditable={globalEditable}
            onSave={onSave}
            {...prop}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "services"}
        onChange={handleChange("services")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            {t("view_task.services")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ServicesComp
            globalEditable={globalEditable}
            onSave={onSave}
            {...prop}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "inventories"}
        onChange={handleChange("inventories")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            {t("view_task.inventories")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <InventoryComp
            globalEditable={globalEditable}
            onSave={onSave}
            {...prop}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "workers"}
        onChange={handleChange("workers")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            {t("view_task.workers")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <WorkersComp
            globalEditable={globalEditable}
            workerData={workerData}
            onSave={onSave}
            {...prop}
          />
        </AccordionDetails>
      </Accordion>
      {viewUser.role > 3 && (
        <Accordion
          expanded={expanded === "costs"}
          onChange={handleChange("costs")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              {t("menus.costs")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {viewTasks.length > 0 && (
              <CostsComp
                globalEditable={globalEditable}
                workerData={workerData}
                onSave={onSave}
                {...prop}
              />
            )}
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
  // return(

  //   <FormStepper {...prop} onFinished={(e)=>console.log("ok")} sendAfterComplete stepsData={TasksSteps} singleForm fields={TasksFields} />
  // )
};
export default TasksView;
