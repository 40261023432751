import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { InputText, FileUploadInput } from "../../../components/Forms";
import { useSelector, useDispatch } from "react-redux";
import TitleComponent from "./TitleComponent";
import { onUpdateInventoryView } from "../../../redux/features/tasks";
import dotenv from "react-dotenv";
import qs from "qs";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import ImageCameraSwitch from "./ImageCameraSwitch";
import axios from "axios";
import { api } from "../../../services/api";
export default function FormDialog({
  view_id,
  status,
  handleClose,
  data,
  onSave,
  globalEditable,
}) {
  const { t } = useTranslation();
  const [title, setTitle] = useState("");
  const [editable, setEditable] = useState(false);
  const [pictureModal, setPictureModal] = useState(null);
  const [amount, setAmount] = useState(0);
  const [room, setRoom] = useState("");
  const [pictures, setPictures] = useState([]);
  const [imageOrcamera, setImageOrCamera] = useState(true);
  const view = useSelector((state) => state.task.view);
  const view_tmp = view[0];
  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      const inventory = view[0].inventory;
      const tmpData = inventory.filter((item) => item.title == data);
      if (tmpData.length > 0) {
        setTitle(tmpData[0].title);
        setAmount(tmpData[0].amount);
        setRoom(tmpData[0].room);
        setPictures(tmpData[0].pictures);
      }
    }
  }, [data]);
  const onChange = (e) => {
    if (e.target.id == "title") {
      setTitle(e.target.value);
    } else if (e.target.id == "amount") {
      setAmount(e.target.value);
    } else if (e.target.id == "room") {
      setRoom(e.target.value);
    }
  };
  const submit = (e) => {
    const view_temp = view[0];

    const index = view_temp.inventory.findIndex((post) => post.title === title);
    if (index > -1) {
      const view2 = {
        view_id: view_id,
        data: {
          ...view_temp,
          inventory: [
            ...view_temp.inventory.slice(0, index),
            { title: title, amount: amount, room: room, pictures: pictures },
            ...view_temp.inventory.slice(index + 1),
          ],
        },
      };
      dispatch(onUpdateInventoryView(view2));
      new Promise((resolve, reject) => {
        api.updateOne(
          { controller: "tasks", id: view_id, data: view2.data },
          (res) => {
            resolve("Success!");
          }
        );
      });
    } else {
      const view2 = {
        view_id: view_id,
        data: {
          ...view_temp,
          inventory: [
            ...view_temp.inventory,
            { title: title, amount: amount, room: room, pictures: pictures },
          ],
        },
      };
      dispatch(onUpdateInventoryView(view2));
      new Promise((resolve, reject) => {
        api.updateOne(
          { controller: "tasks", id: view_id, data: view2.data },
          (res) => {
            resolve("Success!");
          }
        );
      });
    }
    if (data) {
      handleClose();
    } else {
      setTitle("");
      setAmount(1);
      setRoom("");
    }
  };
  const handleTakePhoto = (dataUri) => {
    const data = {
      base64: dataUri.split("data:image/png;base64,")[1],
      inventory_title: title,
      task_id: view_id,
    };
    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: qs.stringify(data),
      url: `${dotenv.base_url}tasks/uploadbase64`,
    };
    axios(options)
      .then((res) => {
        if (res.data) {
          setPictures([...pictures, res.data]);
        }
      })
      .catch((err) => {});
  };
  const onSubmitImage = ({ image_field, images }) => {
    const formData = new FormData();
    images.map((item) => {
      formData.append("images", item.file);
    });
    formData.append("image_field", image_field);
    axios
      .post(`${dotenv.base_url}/tasks/upload_files`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `bearer ${localStorage.getItem("id_token")}`,
        },
      })
      .then((res) => {
        if (res.data) {
          setPictures([...pictures, res.data]);
        }
      })
      .catch((err) => {});
  };
  const onCloseFunc = () => {
    setTitle("");
    setAmount(1);
    setRoom("");
    handleClose();
  };
  return (
    <Dialog
      maxWidth={"md"}
      open={status ? status : false}
      onClose={() => onCloseFunc()}
    >
      <TitleComponent
        enableTitle={true}
        title={t("dialog.inventories")}
        setEditable={() => setEditable(!editable)}
        editable={editable}
      />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} lg={12} md={12}>
            <InputText
              disabled={!globalEditable}
              value={title}
              id="title"
              onChange={(e) => onChange(e)}
              label={t("dialog.title")}
            />
            <InputText
              disabled={!globalEditable}
              value={amount}
              id="amount"
              type={"number"}
              onChange={(e) => onChange(e)}
              label={t("dialog.amount")}
            />
            <InputText
              disabled={!globalEditable}
              value={room}
              id="room"
              onChange={(e) => onChange(e)}
              label={t("dialog.room")}
            />
          </Grid>
          {globalEditable && (
            <Grid item xs={12} sm={12} lg={12} md={12}>
              <ImageCameraSwitch
                checked={imageOrcamera}
                onChange={() => setImageOrCamera(!imageOrcamera)}
              />
              {!imageOrcamera && (
                <FileUploadInput
                  value={pictures}
                  id="gallery"
                  onChange={(e) => {
                    onSubmitImage(e);
                  }}
                />
              )}
              {imageOrcamera && (
                <Camera
                  onTakePhoto={(dataUri) => {
                    handleTakePhoto(dataUri);
                  }}
                />
              )}
            </Grid>
          )}
          <Grid item xs={12} md={12} lg={12} sm={12}>
            {pictures &&
              pictures.map((item, key) => (
                <img
                  style={{ maxHeight: 120, maxWidth: 120 }}
                  src={item}
                  onClick={() => setPictureModal(item)}
                  srcSet={item}
                  loading="lazy"
                />
              ))}
            <Dialog
              maxWidth={"md"}
              open={pictureModal}
              onClose={() => setPictureModal(null)}
            >
              <img src={pictureModal} srcSet={pictureModal} loading="lazy" />
              <Button onClick={() => setPictureModal(null)}>
                {t("dialog.close")}
              </Button>
            </Dialog>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("dialog.cancel")}</Button>
        <Button onClick={submit}>{t("dialog.add")}</Button>
      </DialogActions>
    </Dialog>
  );
}
