import React from "react";
import {
  Dashboard,
  UsersList,
  UsersCreate,
  TasksList,
  TasksCreate,
  TasksView,
  ProfileComponent,
  EventsList,
  UserView,
} from "../../../pages";
import { Route, Switch } from "react-router-dom";
import ClientProfile from "../../../pages/clientProfile/ClientProfile";
const secreter_routes = [
  {
    path: "/app/dashboard",
    component: Dashboard,
  },
  {
    path: "/app/users/list",
    component: UsersList,
  },
  {
    path: "/app/users/create",
    component: UsersCreate,
  },
  {
    path: "/app/tasks/list",
    component: TasksList,
    socket: true,
    socket_name: "tasks",
  },
  {
    path: "/app/tasks/create",
    component: TasksCreate,
    socket: true,
    socket_name: "tasks",
  },
  {
    path: "/app/events",
    component: EventsList,
  },
  {
    path: "/app/tasks/view/:id",
    component: TasksView,
  },
  {
    path: "/app/users/view/:id",
    component: UserView,
  },
  {
    path: "/app/users/:id",
    component: ProfileComponent,
  },
];
const SecreterRoutes = () => {
  // return([])
  return (
    <Switch>
      {secreter_routes.map((item, key) => (
        <Route path={item.path} key={key} component={item.component} />
      ))}
    </Switch>
  );
};
export { SecreterRoutes, secreter_routes };
