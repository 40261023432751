import React from "react";
import {
  Dashboard,
  UsersList,
  UsersCreate,
  TasksList,
  TasksCreate,
  TasksView,
  ProfileComponent,
  EventsList,
  UserView,
  CostsList,
  CostsView,
  CostsCreate,
  CostsBenefits,
} from "../../../pages";
import { Route, Switch } from "react-router-dom";
import ClientProfile from "../../../pages/clientProfile/ClientProfile";
import { AccountingTable } from "../../../pages/Events/components";
import AccountingList from "../../../pages/Accounting";
const user_routes = [
  {
    path: "/app/dashboard",
    component: Dashboard,
  },
  {
    path: "/app/users/list",
    component: UsersList,
  },
  {
    path: "/app/users/create",
    component: UsersCreate,
  },
  {
    path: "/app/tasks/list",
    component: TasksList,
    socket: true,
    socket_name: "tasks",
  },
  {
    path: "/app/tasks/create",
    component: TasksCreate,
    socket: true,
    socket_name: "tasks",
  },
  {
    path: "/app/events",
    component: EventsList,
  },
  {
    path: "/app/accounting",
    component: AccountingList,
  },
  {
    path: "/app/costs/list",
    component: CostsList,
  },
  {
    path: "/app/costs/view/:id",
    component: CostsView,
  },
  {
    path: "/app/costs/create",
    component: CostsCreate,
  },
  {
    path: "/app/costs_and_benefits",
    component: CostsBenefits,
  },
  {
    path: "/app/tasks/view/:id",
    component: TasksView,
  },
  {
    path: "/app/users/view/:id",
    component: UserView,
  },
  {
    path: "/app/users/:id",
    component: ProfileComponent,
  },
];
const UserRoutes = () => {
  // return([])
  return (
    <Switch>
      {user_routes.map((item, key) => (
        <Route path={item.path} key={key} component={item.component} />
      ))}
    </Switch>
  );
};
export { UserRoutes, user_routes };
