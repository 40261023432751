import ApiClient from "./client";
import { ErrorHandler } from "../../components/ErrorHandler/ErrorHandler";
import toast, { Toaster } from "react-hot-toast";
import dotenv from "react-dotenv";
const axios = require("axios");
const RESOURCE_NAME = "";

const endpoints = {
  getAll(controller) {
    return ApiClient.get(`${controller}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response) {
          ErrorHandler(err.response.status);
        }
      });
  },
  getSearchedList(params) {
    const { controller, page, where, sort, select } = params;
    const whereis =
      where &&
      'where={"' +
        where.field +
        '":{"' +
        where.method +
        '":"' +
        where.value +
        '"}}';
    const selectfields = select && `select=${select}&`;
    const parameters = (whereis || "") + (selectfields || "");
    const get_url = `${RESOURCE_NAME}/${controller}?${parameters}`;
    return ApiClient.get(`${get_url}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response) {
          ErrorHandler(err.response.status);
        }
      });
  },
  getList(params) {
    const { controller, page, where, sort, select } = params;
    const whereis = where && `where=${where}&`;
    const selectfields = select && `select=${select}&`;
    const skip = page > 1 ? `skip=${(page - 1) * 10}&` : `skip=0&`;
    const limit = `limit=10`;
    const parameters =
      (whereis || "") + (selectfields || "") + (skip || "") + (limit || "");
    const get_url = `${RESOURCE_NAME}/${controller}?${parameters}`;
    return ApiClient.get(`${get_url}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response) {
          ErrorHandler(err.response.status);
        }
      });
  },
  getOne(params) {
    const { controller, id } = params;
    const get_url = `${RESOURCE_NAME}/${controller}/${id}`;
    return ApiClient.get(`${get_url}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response) {
          ErrorHandler(err.response.status);
        }
      });
  },
  create(params) {
    const { controller, data } = params;
    return ApiClient.post(`${RESOURCE_NAME}/${controller}`, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response) {
          ErrorHandler(err.response.status);
        }
      });
  },
  updateOne(params, callback) {
    const { controller, id, data } = params;
    return ApiClient.patch(`${RESOURCE_NAME}/${controller}/${id}`, data)
      .then((res) => {
        callback(params);
      })
      .catch((err) => {
        if (err.response) {
          ErrorHandler(err.response.status);
        }
      });
  },
  update({ controller, id, data }) {
    return ApiClient.post(`${RESOURCE_NAME}/${controller}/${id}`, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response) {
          ErrorHandler(err.response.status);
        }
      });
  },
  upload_file(params) {
    const { controller, data } = params;
    // data.images.forEach(file=>{
    //   formData.append("images", file);
    // });
    return axios
      .post(`${dotenv.base_url}${controller}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `bearer ${localStorage.getItem("id_token")}`,
        },
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response) {
          ErrorHandler(err.response.status);
        }
      });
  },
  pagination({ controller }) {
    return ApiClient.get(`${RESOURCE_NAME}/pagination/${controller}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response) {
          ErrorHandler(err.response.status);
        }
      });
  },

  //   login(params) {
  //     return ApiClient.post(`${RESOURCE_NAME}/auth/login`, params);
  //   },
  //   get(id) {
  //     return ApiClient.post(`${RESOURCE_NAME}/users/view/${id}`, null);
  //   },
  //   edit(id, params) {
  //     return ApiClient.put(`${RESOURCE_NAME}/users/edit/${id}`, params);
  //   },
  //   getAll(page) {
  //     return ApiClient.post(`${RESOURCE_NAME}/users?page=${page}`, null);
  //   },
  //   test(){
  //     return ApiClient.get(`${RESOURCE_NAME}/user`);
  //   }
};

export default endpoints;
