import React from "react";
import ViewOne from "../../components/ViewOne/ViewOne"
import { api } from "../../services/api";
import { InputButton } from "../../components/Forms";
import { UsersFields } from "../../context/Forms";
import ChangePasswordComp from "./ChangePassword";
const UserView = (prop)=>{
    const [loading,setLoading]=React.useState(false)
    const [autoRefresh,setAutoRefresh] = React.useState(false)
    const onClicktoDeactive = (e)=>{
        setLoading(!loading)
        setTimeout(() => {
            api.updateOne({controller:"user/change_status",id:e.id,data:{status:3}}).then(res=>{
                setAutoRefresh(true)
                setAutoRefresh(false)
                setTimeout(() => {
                    setLoading(false)
                }, 100);
            })
        }, 3000);
    }
    const onClicktoActive = (e)=>{
        setLoading(!loading)
        setTimeout(() => {
            api.updateOne({controller:"user/change_status",id:e.id,data:{status:2}}).then(res=>{
                setAutoRefresh(true)
                setAutoRefresh(false)
                setTimeout(() => {
                    setLoading(false)
                }, 100);
            })
        }, 100);
    }
    return(
        <React.Fragment>
            <ViewOne autoRefresh={autoRefresh} footerContent={(e)=>e.status==2 && !loading ? (
                <InputButton disabled={loading || autoRefresh} loading={loading} onClick={()=>onClicktoDeactive(e)}>Deactive User</InputButton>
            ):(
                <InputButton disabled={loading || autoRefresh} loading={loading} onClick={()=>onClicktoActive(e)}>Active User</InputButton>

            )} manualWithController autoTranslation title={"mobile"} controller="user" id={prop.match.params.id} fields={UsersFields.filter(item=>item.type!=="refrence")} />
            <ChangePasswordComp id={prop.match.params.id} />
        </React.Fragment>
    )
}
export default UserView