import React from "react";
import {
  Grid,
  Radio,
  FormLabel,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";

const InputRadio = ({ data, label, id, value, onChange,disabled,defaultValue }) => {
  const localOnChange = (e)=>{
    if(!disabled){
      onChange(e)
    }else{
    }
  }
  return (
    <FormControl style={{ margin: "10px" }} component="fieldset">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        // spacing={7}
      >
        <Grid item>
          <FormLabel component="legend">{label}</FormLabel>
        </Grid>
        <Grid item>
          <RadioGroup disabled={disabled} row name={id} id={id} value={Number(value)} onChange={localOnChange}>
            {data.map((item,key) => (
              <FormControlLabel key={key} value={item.value} disabled={item.disabled} control={<Radio disabled={item.disabled} id={id} />} label={item.label} />
            ))}
          </RadioGroup>
        </Grid>
      </Grid>
    </FormControl>
  );
};

export { InputRadio };
