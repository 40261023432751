import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Paper,
  Collapse,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Divider,
  Dialog,
  IconButton,
} from "@mui/material";
import {
  Row,
  Transition,
  DestinationLocationComp,
} from "../../../../LocalComponents/Locations";
import { Location } from "../../../../components";
import { useSelector, useDispatch } from "react-redux";
import { AddNewTask } from "../../../../redux/actions/action";
import { task } from "../../../../redux/store";
import { add_destination_in_view } from "../../../../redux/features/tasks";
import TitleComponent from "../TitleComponent";
import { useTranslation } from "react-i18next";

const RenderDestinationComp = (prop) => {
  const { t } = useTranslation();
  const destinationList = useSelector((state) => state.task.view);
  const dispatch = useDispatch();
  const [destination_list, setDestination_list] = useState({});
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    const tmpData = destinationList.filter(
      (item) => item.id === prop.match.params.id
    );
    if (
      tmpData.length > 0 &&
      destinationList.filter((item) => item.id === prop.match.params.id)[0]
        .destination
    ) {
      setDestination_list(
        destinationList.filter((item) => item.id === prop.match.params.id)[0]
          .destination
      );
    }
  }, [destinationList]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleonSaveDestination = (e) => {
    dispatch(add_destination_in_view(e));
    setOpen(false);
  };
  const handleClose = (e) => {
    setOpen(false);
  };
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Address</TableCell>
                <TableCell align="right">
                  {t("destination_location.hnr")}
                </TableCell>
                <TableCell align="right">
                  {t("destination_location.bundesland")}
                </TableCell>
                <TableCell align="right">
                  {t("destination_location.stock")}
                </TableCell>
                <TableCell align="right">
                  {t("destination_location.action")}
                </TableCell>
              </TableRow>
            </TableHead>
            {destination_list.length > 0 && (
              <TableBody>
                {destination_list.map((row) => (
                  <Row
                    {...prop}
                    key={row.address}
                    handleClickOpen={handleClickOpen}
                    task_id={prop.match.params.id}
                    row={row}
                  />
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Grid>
      <Button variant="outlined" onClick={handleClickOpen}>
        {t("destination_location.add_new_destination")}
      </Button>
      <Dialog
        fullScreen
        open={open}
        // onClose={handleClose}
        onClose={() => console.log("close")}
        TransitionComponent={Transition}
      >
        <DestinationLocationComp
          {...prop}
          handleClickOpen={handleClickOpen}
          task_id={prop.match.params.id}
          handleClose={handleClose}
          handleonSaveDestination={(e) => handleonSaveDestination(e)}
        />
      </Dialog>
      <Button onClick={() => prop.onSave()}>
        {t("destination_location.save")}
      </Button>
    </Grid>
  );
};
export default RenderDestinationComp;
