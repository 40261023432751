import React, { useEffect, useState } from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@material-ui/core";
import { InputRadio, InputText } from "../../../../components/Forms";
import { useSelector, useDispatch } from "react-redux";
import { InputSelect } from "../../../../components/Forms";
import { updateField, send_review } from "../../../../redux/features/tasks";
import TitleComponent from "../TitleComponent";
// import { useStyles, options } from "./utils";
import toast from "react-hot-toast";
// import { TrustpilotApi } from "trustpilot";
let run = () => {
  console.log("ok");
  // const client = new TrustpilotApi({
  //   key: "210VYhJLsOOigPsXKp1YJJiZ1V9N7ufL",
  //   secret: "dQabotfTrba3wI0s",
  //   // grantType: "client_credentials",
  //   username: "office@umzugsritter.at",
  //   password: "Macbook2023",
  // }).authenticate();

  // try {
  //   // client(`/v1/private/business-units/5ffcdc1ac37c02000100b50b/reviews`);
  //   // same as
  //   const reviews = client.get(
  //     `/v1/private/business-units/5ffcdc1ac37c02000100b50b/reviews`
  //   );
  //   console.log(client);
  //   console.log(reviews);
  // } catch (error) {
  //   console.log(error);
  // }
};
// const contract_type = {
//   type: "radio",
//   label: i18n.t("events.contract_ype"),
//   id: "contract_type",
//   data: [
//     { value: 1, label: i18n.t("events.personal") },
//     { value: 2, label: i18n.t("events.company") },
//   ],
// };
const status_data = [
  { value: 1, text: i18n.t("tasks_status.created") },
  { value: 2, text: i18n.t("tasks_status.pending") },
  { value: 3, text: i18n.t("tasks_status.accepted") },
  { value: 4, text: i18n.t("tasks_status.doing") },
  { value: 5, text: i18n.t("tasks_status.done") },
  { value: 6, text: i18n.t("events.on_hold") },
  { value: 7, text: i18n.t("tasks_status.cancelled") },
];
const payment_status_data = [
  { value: 1, text: i18n.t("payment_status.cash") },
  { value: 2, text: i18n.t("payment_status.invoice_paid") },
  { value: 3, text: i18n.t("payment_status.invoice_created") },
];
const ContractType = (prop) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const view = useSelector((state) => state.task.view);
  const user = useSelector((state) => state.user.value);
  const [values, setValues] = useState([]);
  const [editable, setEditable] = useState(false);
  const [setLocalReview] = useState(0);
  // const classes = useStyles();
  // const [selected, setSelected] = useState([]);
  // const onClickNextStatus = (e) => {
  //   const tmp = {
  //     task_id: prop.match.params.id,
  //     id: "status",
  //     data: Number(view[0].status) + 1,
  //   };
  //   dispatch(updateField(tmp));
  // };

  // const isAllSelected =
  //   options.length > 0 && selected.length === options.length;

  useEffect(() => {
    setValues(view.filter((item) => item.id === prop.match.params.id));
    run();
  }, [view]);
  const onChange = (e) => {
    if (
      e.target.id === "status" &&
      values[0].status < 2 &&
      (values[0].start_time === "" || values[0].end_time === "")
    ) {
      return toast.error(
        t("view_task.please_select_start_and_end_time_from_meeting_date")
      );
    } else if (e.target.id === "review") {
      dispatch(
        send_review({ task_id: prop.match.params.id, id: "review", data: true })
      );
    } else {
      const tmp = {
        task_id: prop.match.params.id,
        id: e.target.id,
        data: e.target.value,
      };

      dispatch(updateField(tmp));
    }
  };
  // const handleChange = (event) => {
  //   const value = event.target.value;
  //   if (value[value.length - 1] === "all") {
  //     setSelected(selected.length === options.length ? [] : options);
  //     return;
  //   }
  //   setSelected(value);
  // };

  if (values.length > 0) {
    return (
      <Grid container>
        <TitleComponent
          title={"Personal Info"}
          setEditable={() => setEditable(!editable)}
          editable={editable}
        />
        <Grid item xs={10} lg={10} sm={10} md={10}>
          <InputRadio
            disabled={!prop.globalEditable}
            value={values[0].contract_type}
            onChange={(e) => onChange(e)}
            id="contract_type"
            data={[
              { label: t("tasks.personal"), value: 1 },
              { value: 2, label: t("tasks.company") },
            ]}
            label={t("events.contract_type")}
          />
        </Grid>
        {values[0].contract_type === 1 && (
          <React.Fragment>
            <Grid item xs={10} lg={3} sm={4} md={4}>
              <InputText
                disabled={!prop.globalEditable}
                value={values[0].title}
                id="title"
                onChange={(e) => onChange(e)}
                label={t("view_task.title")}
              />
            </Grid>
            <Grid item xs={11} lg={8} sm={7} md={7}>
              <InputText
                disabled={!prop.globalEditable}
                value={values[0].name}
                id="name"
                onChange={(e) => onChange(e)}
                label={t("view_task.name")}
              />
            </Grid>
          </React.Fragment>
        )}
        {values[0].contract_type == 2 && (
          <React.Fragment>
            <Grid item xs={10} lg={10} sm={10} md={10}>
              <InputText
                disabled={!prop.globalEditable}
                value={values[0].company_name}
                id="company_name"
                onChange={(e) => onChange(e)}
                label={t("events.company_name")}
              />
            </Grid>
          </React.Fragment>
        )}
        <Grid item xs={10} lg={5} sm={11} md={11}>
          <InputText
            disabled={!prop.globalEditable}
            customType={"number"}
            value={values[0].phone}
            id="phone"
            onChange={(e) => onChange(e)}
            label={t("events.phone_number")}
          />
        </Grid>
        <Grid item xs={10} lg={6} sm={10} md={10}>
          <InputText
            value={values[0].email}
            disabled={!prop.globalEditable}
            id="email"
            onChange={(e) => onChange(e)}
            label={t("tasks.email")}
            customType={"text"}
          />
        </Grid>

        {user.role > 2 && (
          <React.Fragment>
            <Grid item xs={10} lg={5} sm={10} md={10}>
              <InputRadio
                disabled={!prop.globalEditable}
                value={values[0].contract_price_type}
                onChange={(e) => onChange(e)}
                id="contract_price_type"
                data={[
                  { label: t("tasks.hourly_price"), value: 1 },
                  { value: 2, label: t("tasks.contract_price") },
                ]}
                label={t("events.contract_price_type")}
              />
            </Grid>
            {values[0].contract_price_type == 1 && (
              <Grid item xs={10} lg={5} sm={10} md={10}>
                <InputText
                  value={values[0].price}
                  disabled={!prop.globalEditable}
                  id="price"
                  onChange={(e) => onChange(e)}
                  label={t("events.price")}
                  customType={"number"}
                  endAdornmentText={t("tasks.hourly_price")}
                />
              </Grid>
            )}
            {values[0].contract_price_type == 2 && (
              <Grid item xs={10} lg={6} sm={10} md={10}>
                <InputText
                  value={values[0].price}
                  id="price"
                  disabled={!prop.globalEditable}
                  onChange={(e) => onChange(e)}
                  label={t("events.price")}
                  customType={"number"}
                  endAdornmentText={t("tasks.contract_price")}
                />
              </Grid>
            )}
          </React.Fragment>
        )}
        {/* <Grid item xs={12} lg={12} sm={12} md={12}>
                    <Typography>Meeting Start and end Time</Typography>
                    <DatetimeRangePicker 
                        inputProps={{disabled:!editable,className:"styleStartEndInput"}} inline={true} 
                        onChange={(e)=>{
                            onChange({target:{id:"start_time",data:moment(e.start).format("X")}})
                            onChange({target:{id:"end_time",data:moment(e.end).format("X")}})
                        }} 
                    />
                </Grid> */}
        <Grid item xs={10} lg={4} sm={10} md={10}>
          <InputRadio
            value={values[0].pcr_test}
            disabled={!prop.globalEditable}
            onChange={(e) => onChange(e)}
            id="pcr_test"
            data={[
              { label: t("tasks.pcr_list.no"), value: 1 },
              { value: 2, label: t("tasks.pcr_list.yes") },
            ]}
            label={t("tasks.pcr")}
          />
        </Grid>
        <Grid item xs={11} lg={5} sm={11} md={11}>
          <InputRadio
            value={values[0].company}
            disabled={!prop.globalEditable}
            onChange={(e) => onChange(e)}
            id="company"
            data={[
              { label: "UmzugsRitter", value: 1 },
              { value: 2, label: "Herzug Umzug" },
            ]}
            label={t("tasks.owner")}
          />
        </Grid>
        <Grid item xs={10} lg={10} sm={10} md={10}>
          <Grid container>
            <Grid item xs={12} md={6} sm={6} lg={6}>
              <InputText
                value={values[0].description}
                disabled={!prop.globalEditable}
                onChange={(e) => onChange(e)}
                id="description"
                label={t("tasks.description")}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} sm={6}>
              <InputText
                value={values[0].car_tag}
                disabled={!prop.globalEditable}
                onChange={(e) => onChange(e)}
                id="car_tag"
                label={t("tasks.car_tag")}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={10} lg={4} sm={10} md={10}>
          <InputSelect
            disabled={!prop.globalEditable}
            data={payment_status_data}
            id="payment_status"
            value={values[0].payment_status}
            onChange={(e) => onChange(e)}
            label={t("tasks_status.payment_status")}
          />
        </Grid>
        <Grid item xs={10} lg={3} sm={10} md={10}>
          <InputSelect
            disabled={!prop.globalEditable}
            data={status_data}
            id="status"
            value={values[0].status}
            onChange={(e) => onChange(e)}
            label={t("tasks_status.status")}
            // withNext={{ min: 1, max: 5, onClick: () => onClickNextStatus }}
          />
          {values[0].status == 5 && prop.globalEditable ? (
            <InputRadio
              value={prop.review}
              disabled={!prop.globalEditable}
              onChange={(e) => {
                onChange(e);
                console.log(e.target.value);
                prop.sendReview(e.target.value);
                setLocalReview(e.target.value);
              }}
              id="review"
              data={[
                { label: "Send", value: 1 },
                { value: 0, label: "Without Send" },
              ]}
              label={t("tasks.review")}
            />
          ) : (
            <React.Fragment />
          )}

          {/* <Grid container>
                        <Grid xs={12} md={6} lg={6} sm={6}>
                            <InputSelect label="eeoj" onChange={(e)=>console.log(e.target.value)} data={datatemp} id="ewew" multiple />
                        <div className={classes.formControl}>
                            <InputLabel id="mutiple-select-label">Multiple Select</InputLabel>
                            <Select
                                labelId="mutiple-select-label"
                                multiple
                                className={classes.input}
                                value={selected}
                                onChange={handleChange}
                                renderValue={(selected) => selected.join(", ")}
                                MenuProps={MenuProps}
                            >
                                <MenuItem
                                value="all"
                                classes={{
                                    root: isAllSelected ? classes.selectedAll : ""
                                }}
                                >
                                <ListItemIcon>
                                    <Checkbox
                                    classes={{ indeterminate: classes.indeterminateColor }}
                                    checked={isAllSelected}
                                    indeterminate={
                                        selected.length > 0 && selected.length < options.length
                                    }
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    classes={{ primary: classes.selectAllText }}
                                    primary="Select All"
                                />
                                </MenuItem>
                                {options.map((option) => (
                                <MenuItem key={option} value={option}>
                                    <ListItemIcon>
                                    <Checkbox checked={selected.indexOf(option) > -1} />
                                    </ListItemIcon>
                                    <ListItemText primary={option} />
                                </MenuItem>
                                ))}
                            </Select>
                        </div>
                        </Grid>
                        <Grid xs={12} md={6} lg={6} sm={6}>
                            www
                        </Grid>
                    </Grid> */}
        </Grid>
        {editable && (
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <Button onClick={() => prop.onSave()}>Save</Button>
          </Grid>
        )}
      </Grid>
    );
  } else {
    return <div></div>;
  }

  // return(
  //     <Grid container>
  //         <Grid item xs={12} lg={12} sm={12} md={12}><InputRadio  value={task.contract_type} onChange={(e)=>onChange(e)}  id="contract_type"  data={[{label:"Personal",value:1},{value:2,label:"Company"}]} label={"Contract Type"}  /></Grid>
  //         {task.contract_type==1 && (
  //             <React.Fragment>
  //                 <Grid item xs={12} lg={12} sm={12} md={12}><InputText   value={task.title} id="title"  onChange={(e)=>onChange(e)} label={"Title"}         /></Grid>
  //                 <Grid item xs={12} lg={12} sm={12} md={12}><InputText   value={task.name} id="name"  onChange={(e)=>onChange(e)} label={"Name"}          /></Grid>
  //             </React.Fragment>
  //         )}
  //         {task.contract_type==2 && (
  //             <React.Fragment>
  //                 <Grid item xs={12} lg={12} sm={12} md={12}><InputText   value={task.company_name} id="company_name"  onChange={(e)=>onChange(e)} label={"Company Name"}  /></Grid>
  //             </React.Fragment>
  //         )}
  //         <Grid item xs={12} lg={12} sm={12} md={12}><InputText   value={task.phone} id="phone"  onChange={(e)=>onChange(e)} label={"Phone"}  /></Grid>
  //     </Grid>
  // )
};
export default ContractType;
