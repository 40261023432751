import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import { TasksFields } from '../../context/Forms'
import PageTitle from "../../components/PageTitle/PageTitle";
import { Button, Grid, TextField, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel,Chip } from "@mui/material";
import { ListView } from "../../components/ListView";
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { api } from "../../services/api";
import Autocomplete from '@mui/material/Autocomplete';
import {
  Business,
  PersonPin,
  Coronavirus,
} from "@mui/icons-material";


const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
  cellWidth: {
    width: "30%",
  },
});

const TasksList = (props) => {
  const classes = useStyles();
  const user = useSelector(state => state.user.value)
  const company = useSelector(state => state.user.company)
  const {t} = useTranslation();
  const [value, setValue] = React.useState(null);
  const [searchedList, setSearchedList] = useState([])
  const [searchField, setSearchField] = useState('name')
  const [blackList,setBlackList] = useState(false)
  const onChangeSearchInput = (e) => {
    if (e.key==="Enter") {
      if(e.target.value==="Black:List:Working"){
        setBlackList(true)
      }else{
        api.getSearchedList({ controller: "tasks", where: { field: searchField, method: "startsWith", value: e.target.value } })
          .then(res => {
            const {list} = res
            setSearchedList(list.map(item => { return { id: item.id, title: item.name + " " + item.phone } }))
          })
      }
    }
  }
  return (
    <React.Fragment>
      <PageTitle title={t("tasks.title")} button={user.role > 2 &&
        (
          <Button component={Link} to="/app/tasks/create" variant="contained" size="medium" color="primary">
            {t("events.create")}
          </Button>
        )}
      />
      <div className={classes.root}>
        <Grid container>
          <Grid item style={{ border: '1px solid #d7dee6', borderRadius:0, margin: 10, padding: 15 }} xs={12} md={12} lg={12} sm={12}>
            <Autocomplete
              style={{ width: 'auto' }}
              value={value}
              onChange={(event, newValue) => {
                props.history.push(`./view/${newValue.id}`)
                if (typeof newValue === 'string') {
                  setValue({
                    title: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  setValue({
                    title: newValue.inputValue,
                  });
                } else {
                  setValue(newValue);
                }
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="search"
              options={searchedList}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
              renderOption={(props, option) => <li {...props}>{option.title}</li>}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} onKeyDown={(e)=>onChangeSearchInput(e)} label={t("search")} />
              )}
            />
            <FormControl component="fieldset">
              <FormLabel component="legend">{t("events.search_with")}:</FormLabel>
              <RadioGroup row value={searchField} onChange={(e) => setSearchField(e.target.value)} aria-label="field" name="row-radio-buttons-group">
                <FormControlLabel value="name" control={<Radio />} label={t("events.name")} />
                <FormControlLabel value="company_name" control={<Radio />} label={t("events.company_name")} />
                <FormControlLabel value="phone" control={<Radio />} label={t("events.phone_number")} />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
        </Grid>
        {(blackList && user.role>2) && (
          <ListView autoTranslation notFoundTitle="global_not_found" withShow pagination title={"eee"} controller={"tasks/findallb"} fields={TasksFields} />
        )}
        <ListView autoTranslation
        avatar={(e)=>(<img width={64} height={64} src={e.company ==2 ?"https://dashboard.umzugsritter.at/assets/herzog.png":"https://dashboard.umzugsritter.at/assets/logo.jpg"} />)}
         footerContent={(e) => (
            <Grid item>
              <Grid container>
                <Grid item>
                  <Chip
                    avatar={
                      <React.Fragment>
                        {e.contract_type === 1 ? <PersonPin /> : <Business />}
                        {e.pcr_test == 2 && <Coronavirus />}
                      </React.Fragment>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          )} notFoundTitle="global_not_found" withShow withPagination controller={company && company!=="all" && user.role>2?`tasks/${company}`:'tasks'} fields={TasksFields} />
      </div>
    </React.Fragment>
  );
};
export default TasksList;
