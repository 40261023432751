import React, { useState } from "react";
import { Pagination,List,Chip,Box,IconButton } from "@mui/material";
import usePagination from '@mui/material/usePagination';
const ListViewPagination = ({total,handleOnChange}) => {
  const tofixHandler = (e)=>{
    const temp = e/10;
    if(temp<temp.toFixed()){
      return Number(temp.toFixed())
    }else if(temp==temp.toFixed()) return Number(temp)
    else return Number(temp.toFixed())+1
  }
  const [page, setPage] = useState(0);
  const { items } = usePagination({
    count: total/10>0?tofixHandler(total):1,
    onChange:(e,b)=>handleChangePage(e,b)
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handleOnChange(newPage)
  };
  return(
    <List>
      {items.map(({ page, type, selected, ...item }, index) => {
        let children = null;
        if (type === 'start-ellipsis' || type === 'end-ellipsis') {
          children = '…';
        } else if (type === 'page') {
          children = (
            <IconButton
            onChange={()=>console.log("ok")}
              style={{
                marginLeft:8,
                marginRight:8,
                fontFamily:"Yekan",
                fontSize:13,
                // margin:4,
                // padding:2,
                fontWeight: selected ? 'bold' : undefined,
              }}
              {...item}
            >
              {page.toFixed()}
            </IconButton>
          );
        } else {
          children = parseInt(total/10)>1 ? (
            <IconButton type="button" style={{fontFamily:"Yekan",fontSize:13,paddingLeft:10,paddingRight:10}} {...item}>
              {type==="next"?"Next":"Prev"}
            </IconButton>
          ):(
            <React.Fragment><IconButton type="button" disabled style={{fontFamily:"Yekan",fontSize:13,paddingLeft:10,paddingRight:10}}>
            {type==="next"?"Next":"Prev"}
            </IconButton></React.Fragment>
          );
        }
        return <span key={index} onChange={handleChangePage}>
          {children}
        </span>
      })}
    </List>
  )

};

export { ListViewPagination };
