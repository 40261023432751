import React, { useState } from "react";
import {
  Box,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableFooter,
  TablePagination,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import { useSelector } from "react-redux";
import { GridTableHeader, GridTableBody, GridTableFooter, localData } from "../";

import { useTheme } from "@mui/material/styles";

const GridTable = ({ title, fields, controller, localData, pagination }) => {
  // const user = useSelector(state.user.value)
  // if(controller==="tasks"){
  //   const tasks = useSelector(state=>state.tasks)
  // }
  const [page, setPage] = useState(1)
  const headers = [];
  return (
    <TableContainer>
      <Table>
        <GridTableHeader data={fields} />
        <GridTableBody page={page} localData={localData} controller={controller} data={fields} />
      </Table>
      {pagination && (<GridTableFooter controller={controller} page={page} onChangePage={(e) => setPage(e)} data={[]} />)}
    </TableContainer>
  );
};

GridTable.defaultProps = {
  title: "No Title",
};

export { GridTable };
