import React, { useEffect, useState } from "react";
import i18n from "i18next";
import { Grid } from "@material-ui/core";
import {
  InputRadio,
  InputText,
  DatePicker,
  InputSelect,
} from "../../../../components/Forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { task } from "../../../../redux/store";
import { add } from "../../../../redux/features/tasks";
const moment = require("moment");
const contract_type = {
  type: "radio",
  label: i18n.t("events.contract_type"),
  id: "contract_type",
  data: [
    { value: 1, label: i18n.t("events.personal") },
    { value: 2, label: i18n.t("events.company") },
  ],
};
const ContractType = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const new_task = useSelector((state) => state.models.new_task);
  const tasks = useSelector((state) => state.task.new);
  const [values, setValues] = useState(new_task);
  useEffect(() => {
    setValues(new_task);
  }, [new_task, tasks]);
  const onChange = (e) => {
    console.log(e.target);
    const data = { id: e.target.id, data: e.target.value };
    dispatch(add(data));
    // dispatch(AddNewTask(data))
  };
  const status_data = [
    { value: 1, text: i18n.t("tasks_status.created") },
    { value: 2, text: i18n.t("tasks_status.pending") },
    { value: 3, text: i18n.t("tasks_status.accepted") },
    { value: 4, text: i18n.t("tasks_status.doing") },
    { value: 5, text: i18n.t("tasks_status.done") },
    { value: 6, text: i18n.t("tasks_status.on_hold") },
    { value: 7, text: i18n.t("tasks_status.cancelled") },
  ];
  const payment_status_data = [
    { value: 1, text: i18n.t("payment_status.cash") },
    { value: 2, text: i18n.t("payment_status.invoice_paid") },
    { value: 3, text: i18n.t("payment_status.invoice_created") },
  ];
  return (
    <Grid container>
      <Grid item xs={11} lg={11} sm={11} md={11}>
        <InputRadio
          value={tasks.contract_type}
          onChange={(e) => onChange(e)}
          id="contract_type"
          data={[
            { label: t("tasks.personal"), value: 1 },
            { value: 2, label: t("tasks.company") },
          ]}
          label={t("events.contract_type")}
        />
      </Grid>
      {tasks.contract_type == 1 && (
        <React.Fragment>
          <Grid item xs={11} lg={3} sm={4} md={4}>
            <InputText
              value={tasks.title}
              id="title"
              onChange={(e) => onChange(e)}
              label={t("events.title")}
            />
          </Grid>
          <Grid item xs={11} lg={8} sm={7} md={7}>
            <InputText
              value={tasks.name}
              id="name"
              onChange={(e) => onChange(e)}
              label={t("events.name")}
            />
          </Grid>
        </React.Fragment>
      )}
      {tasks.contract_type == 2 && (
        <React.Fragment>
          <Grid item xs={11} lg={11} sm={11} md={11}>
            <InputText
              value={tasks.company_name}
              id="company_name"
              onChange={(e) => {
                onChange(e);
              }}
              label={t("events.company_name")}
            />
          </Grid>
        </React.Fragment>
      )}
      <Grid item xs={11} lg={5} sm={11} md={11}>
        <InputText
          value={tasks.phone}
          id="phone"
          onChange={(e) => onChange(e)}
          label={t("events.phone_number")}
          customType={"number"}
        />
      </Grid>
      <Grid item xs={11} lg={6} sm={11} md={11}>
        <InputText
          value={tasks.email}
          id="email"
          onChange={(e) => onChange(e)}
          label={t("tasks.email")}
          customType={"text"}
        />
      </Grid>
      <Grid item xs={11} lg={5} sm={11} md={11}>
        <InputRadio
          value={tasks.contract_price_type}
          onChange={(e) => onChange(e)}
          id="contract_price_type"
          data={[
            { label: t("tasks.hourly_price"), value: 1 },
            { value: 2, label: t("tasks.contract_price") },
          ]}
          label={t("events.contract_price_type")}
        />
      </Grid>
      {tasks.contract_price_type == 1 && (
        <Grid item xs={11} lg={6} sm={11} md={11}>
          <InputText
            value={tasks.price}
            id="price"
            onChange={(e) => onChange(e)}
            label={t("events.price")}
            customType={"number"}
            endAdornmentText={t("tasks.hourly_price")}
          />
        </Grid>
      )}
      {tasks.contract_price_type == 2 && (
        <Grid item xs={11} lg={6} sm={11} md={11}>
          <InputText
            value={tasks.price}
            id="price"
            onChange={(e) => onChange(e)}
            label={t("events.price")}
            customType={"number"}
            endAdornmentText={t("tasks.contract_price")}
          />
        </Grid>
      )}
      <Grid item xs={11} lg={5} sm={11} md={11}>
        <InputRadio
          value={tasks.pcr_test}
          onChange={(e) => onChange(e)}
          id="pcr_test"
          data={[
            { label: t("tasks.pcr_list.no"), value: 1 },
            { value: 2, label: t("tasks.pcr_list.yes") },
          ]}
          label={t("tasks.pcr")}
        />
      </Grid>
      <Grid item xs={11} lg={5} sm={11} md={11}>
        <InputRadio
          value={tasks.company}
          onChange={(e) => onChange(e)}
          id="company"
          data={[
            { label: "UmzugsRitter", value: 1 },
            { value: 2, label: "Herzug Umzug" },
          ]}
          label={t("tasks.owner")}
        />
      </Grid>
      <Grid item xs={11} lg={11} sm={11} md={11}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6} sm={6} md={6}>
            <DatePicker
              onChange={onChange}
              label={t("view_task.meeting_start_time")}
              id="start_time"
              // disabled={!prop.globalEditable}
              value={tasks.start_time}
            />
          </Grid>
          <Grid item xs={12} lg={6} sm={6} md={6}>
            <DatePicker
              onChange={onChange}
              label={t("view_task.meeting_end_time")}
              id="end_time"
              // disabled={!prop.globalEditable}
              value={tasks.end_time}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <InputText
            onChange={onChange}
            label={t("tasks.description")}
            id="description"
            // disabled={!prop.globalEditable}
            value={tasks.description}
          />
        </Grid>
        {/* <DatePicker
          onChange={(e) => {
            onChange(e);
            console.log(e.target);
          }}
          label={t("events.meeting_time")}
          id="meeting_time"
          value={tasks.meeting_time}
        /> */}
      </Grid>
      <Grid item xs={12} lg={12} sm={12} md={12}>
        <Grid container>
          <Grid item xs={11} md={5} sm={5} lg={5}>
            <InputSelect
              data={payment_status_data}
              id="payment_status"
              value={tasks.payment_status}
              onChange={(e) => onChange(e)}
              label={t("tasks_status.payment_status")}
            />
          </Grid>
          <Grid item xs={11} md={6} sm={6} lg={6}>
            <InputSelect
              data={status_data}
              id="status"
              value={tasks.status}
              onChange={(e) => onChange(e)}
              label={t("tasks_status.status")}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ContractType;
