import React from "react";
// import Timeline from 'react-calendar-timeline'
import moment from 'moment'
// import 'react-vertical-timeline-component/style.min.css';
// import 'react-calendar-timeline/lib/Timeline.css'
const defaultTimeEnd = moment().startOf('day').add(1, 'day').toDate()
const defaultTimeStart = moment().startOf('day').toDate()
var keys = {
    groupIdKey: 'id',
    groupTitleKey: 'title',
    groupRightTitleKey: 'rightTitle',
    itemIdKey: 'id',
    itemTitleKey: 'title',
    itemDivTitleKey: 'title',
    itemGroupKey: 'group',
    itemTimeStartKey: 'start',
    itemTimeEndKey: 'end'
  }
  const itemRenderer = ({ item, timelineContext, itemContext, getItemProps, getResizeProps }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    return (
      <div
        {...getItemProps({
          style: {
            background: itemContext.selected ? "white" : "gray",
            color: item.color,
            borderRadius: 4,
            borderLeftWidth: itemContext.selected ? 3 : 1,
            borderRightWidth: itemContext.selected ? 3 : 1
          },
          // onClick:{console.log("clicked")},
          onMouseDown: () => {
          }
        })}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
        <div
          style={{
            height: itemContext.dimensions.height,
            overflow: "hidden",
            paddingLeft: 3,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
          }}
        >
          {/* {itemContext.title} */}
          {itemContext.title}
        </div>
        {itemContext.useResizeHandle && (<div {...rightResizeProps} />)}
      </div>
    );
  };
  
const EventsWorker = (props)=>{
    const {tasksData,workers,history} = props
    return(<div>ok</div>
        // <Timeline
        //     groups={workers}
        //     // groups={groups}
        //     items={tasksData}
        //     // items={items}
        //     keys={keys}
        //     sidebarContent={<div>Above The Left</div>}
        //     itemsSorted
        //     itemTouchSendsClick={false}
        //     stackItems
        //     // itemHeightRatio={0.75}
        //     showCursorLine
        //     canMove={false}
        //     canResize={false}
        //     onItemClick={(itemId, e, time) => history.push("tasks/view/" + itemId.split("|")[0])}
        //     itemRenderer={itemRenderer}
        //     defaultTimeStart={defaultTimeStart}
        //     defaultTimeEnd={defaultTimeEnd}
        // />
    )
}
export {EventsWorker}