import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {EventsTable,EventsWorker,AccountingTable} from "./components"
import { List, ListItem, ListItemText, MenuItem, Menu, Box, Button } from '@mui/material';
import { TasksFields } from '../../context/Forms'
import PageTitle from "../../components/PageTitle/PageTitle";
import OutlinedInput from '@mui/material/OutlinedInput';
import { useTheme } from '@mui/material/styles';
import Select from '@mui/material/Select';
import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import {  getStyles, status_list, MenuProps,FormControl,InputLabel,CustomizedInputs } from "./components/styles/SelectStyles"
import moment from 'moment'
import { api } from "../../services/api";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
// const options = [
//   'Created (Meeting Time)',
//   'Pending for Accept Workers',
//   'Accepted from all workers',
//   'Doing Tasks now',
//   'Done Task',
//   'on Hold Task',
//   'Cancelled Task'
// ];
const useStyles = makeStyles({
  root: { flexGrow: 1 },
  table: { minWidth: 650 },
  cellWidth: { width: "30%" },
});

const EventsList = (props) => {
  const {t} = useTranslation()
  const theme = useTheme();
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = useState(true)
  const user = useSelector(state => state.user.value);
  const tasks = useSelector(state => state.tasks);
  const [meetings, setMeetings] = useState(null)
  const [workers, setWorkers] = useState([])
  const [accounting,setAccounting] = useState(false)
  const [tasksData, setTasksData] = useState([])
  const [allTasks, setAllTasks] = useState([])
  const [statusMenu, setStatusMenu] = useState(null)
  const [selectedStatusMenu, setSelectedStatusMenu] = useState([3])
  const [dateRange,setDateRange] = useState([
    {
      startDate: new Date(moment()),
      endDate: new Date(moment().add(10, 'days').calendar()),
      key: "selection",
    },
  ])
  const [tab, setTab] = useState("admin")
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const options = [
    t("view_task.status.created"),
    t("view_task.status.pending_for_accept_workers"),
    t("view_task.status.accepted_from_all_workers"),
    t("view_task.status.doing_tasks_now"),
    t("view_task.status.done"),
    t("view_task.status.on_hold_tasks"),
    t("view_task.status.cancelled_task"),
  ];
  const open = Boolean(statusMenu);
  useEffect(() => {
    if (tab === "worker") {
      setLoading(true)
      api.getAll(`events/find_admin?&sort=meeting_time%20DESC`).then(res => {
        setAllTasks(res.task)
        setWorkers(res.workers)
        const task_data = res.task_tmp.filter(item => item.start && item.end).map(item => {
          item.start = moment(item.start)
          item.end = moment(item.end)
          return item
        })
        setTasksData(task_data)
        setLoading(false)
      })
    }
  }, [tasks, tab])
  useEffect(() => {
    if (tab === "admin") {
      const startTimeTmp = moment(dateRange[0].startDate).format("l")
      const endTimeTmp =moment(dateRange[0].endDate).format("l")
      const start_time = [`"${startTimeTmp.split("/")[0]}-${startTimeTmp.split("/")[1]}-${startTimeTmp.split("/")[2]}"`,`"${endTimeTmp.split("/")[0]}-${endTimeTmp.split("/")[1]}-${endTimeTmp.split("/")[2]}"`]
      api.getAll(`events/find_meetings?start_time=${startTimeTmp.split("/")[1]}-${startTimeTmp.split("/")[0]}-${startTimeTmp.split("/")[2]}&end_time=${endTimeTmp.split("/")[1]}-${endTimeTmp.split("/")[0]}-${endTimeTmp.split("/")[2]}&status=[${selectedStatusMenu}]&sort=start_time%20DESC`).then(res => {
        setMeetings(res)
        setLoading(false)
      })
    }
  }, [])
  useEffect(() => {
    if (tab === "admin") {
      const startTimeTmp = moment(dateRange[0].startDate).format("l")
      const endTimeTmp =moment(dateRange[0].endDate).format("l")
      const start_time = [`"${startTimeTmp.split("/")[1]}-${startTimeTmp.split("/")[0]}-${startTimeTmp.split("/")[2]}"`,`"${endTimeTmp.split("/")[1]}-${endTimeTmp.split("/")[0]}-${endTimeTmp.split("/")[2]}"`]
      api.getAll(`events/find_meetings?start_time=${startTimeTmp.split("/")[1]}-${startTimeTmp.split("/")[0]}-${startTimeTmp.split("/")[2]}&end_time=${endTimeTmp.split("/")[1]}-${endTimeTmp.split("/")[0]}-${endTimeTmp.split("/")[2]}&status=[${selectedStatusMenu}]&sort=start_time%20DESC`).then(res => {
        setMeetings(res)
        setLoading(false)
      })
    }
  }, [tab, selectedStatusMenu,dateRange])
  const handleCloseStatusMenu = () => { setStatusMenu(null); };
  const handleClickListItemStatusMenu = (event) => {
    setStatusMenu(event.currentTarget);
  };
  const handleStatusMenuItemClick = (event, index) => {
    setSelectedStatusMenu(index);
    setStatusMenu(null);
  };
  const handleChangeStatus = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    setSelectedStatusMenu(
      typeof value === 'string' ? value.split(',') : value,
    )
  };
  const [personName, setPersonName] = React.useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    setSelectedStatusMenu(
      typeof value === 'string' ? value.split(',') : value,
    )
  };

  const preventDefault = (event) => event.preventDefault();
  if (loading) return (<div>Loading</div>)
  return (
    <React.Fragment>
      <PageTitle title={t("events.events_title")} />
      <div className={classes.root}>
        <Grid container justifyContent="space-between">
          <Grid item xs={3} md={2} lg={2} sm={2}>
            <Button onClick={() => tab === "admin" ? setTab("worker") : setTab("admin")}>{tab === "admin" ? t("events.tasks_text") : t("events.meeting_text")}</Button>
            {user.role == 4 && (
              <Button onClick={() => setAccounting(!accounting)}>Accounting</Button>
            )}
          </Grid>
          {(tab === "admin" || accounting) && (
            <Grid item xs={12} md={12} lg={3} sm={6}>
              {t("events.select_status")}
              <FormControl sx={{ m: 1, width: 300 }}>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={selectedStatusMenu}
                  onChange={handleChangeStatus}
                  // input={<CustomizedInputs label="Name" />}
                  MenuProps={MenuProps}
                >
                  {status_list.map((name) => (
                    <MenuItem
                      key={name.value}
                      value={name.value}
                      style={getStyles(name, personName, theme)}
                    >
                      {name.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
        {(accounting && user.role>3) && (
          <AccountingTable dateRange={dateRange} onChangeDateRange={(e)=>setDateRange(e)} history={props.history} meetings={meetings} />
        )}
        {(!accounting && tab === "admin") && (
          <React.Fragment>
            {meetings && (
              <EventsTable dateRange={dateRange} onChangeDateRange={(e)=>setDateRange(e)} history={props.history} meetings={meetings} />
            )}
          </React.Fragment>
        )}
        {(!accounting && tab === "worker") && (<EventsWorker tasksData={tasksData} workers={workers} history={props.history} />)}
      </div>
    </React.Fragment>
  );
};
export default EventsList;
