import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import { makeStyles } from "@mui/styles";
import { withStyles } from "@mui/styles";

// import {StyledFormControl} from "../StyledComponents";

// import Autocomplete from "@material-ui/lab/Autocomplete";
import { Autocomplete, Button } from '@mui/material';
import { NavigateNext } from "@mui/icons-material";
import {TextField} from '@mui/material';
import {
  Select,
  FormControl,
  LinearProgress,
  Grid,
} from "@material-ui/core";
import { api } from "./../../../services/api";
import { StyledInputLabel, StyledInputLabelActive } from "../StyledComponents";
// const StyledInputLabel = withStyles((theme) => ({
//   root: {
//     background: "#fff",
//     padding: "5px",
//     borderRadius: "5px",
//     border: "1px solid",
//   },
// }))(InputLabel);

const StyledFormControl = withStyles((theme) => ({
  root: {
    paddingRight: 3,
    width: "100%",
    margin: '10px',
    transition: 'border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    position: "relative",
    backgroundColor: "#fcfcfb",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
}))(FormControl);

const StyledAutoComplete = withStyles((theme) => ({
  root: {
    paddingRight: 3,
    width: "100%",
    margin: 10,
    position: "relative",
    backgroundColor: "#fcfcfb",
    borderRadius: 5,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
}))(Autocomplete);

const StyledLinearProgress = withStyles((theme) => ({
  root: {
    marginTop: 30,
    marginBottom: 30,
    marginLeft: 15,
  },
}))(LinearProgress);

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  root: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 48,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
  focused: {
    borderRadius: "5px",
    border: "1px solid",
    padding: "5px",
    marginTop: "-20px",
  },
  label: {
    root: {
      background: "#000",
    },
    focused: {
      borderRadius: "5px",
      border: "1px solid",
      padding: "5px",
      marginTop: "-20px",
    },
    textTransform: "capitalize",
    background: "black",
  },
});
const selected_style = "lightgray"
const CssTextField = withStyles({
  root: {
    borderRadius: "none",
    "& label.Mui-focused": {
      border: "1px solid #b9b9b8",
      padding: 5,
      borderRadius: "none",
    },
    "& label.MuiFormLabel-root": {
      padding: 5,
      background: "#fff",
      borderRadius: "none",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      border: "1px solid",
      marginLeft: "-4px",
      minWidth: 65,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "blue",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 0,
      "& fieldset": {
        borderColor: "light-dark",
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "blue",
      },
    },
  },
})(TextField);

const InputSelect = ({
  onChange,
  value,
  id,
  native,
  children,
  variant,
  labelId,
  label,
  data,
  otherOptions,
  noneExist,
  withNext,
  disabled,
  onClickNextStatus,
  controller,
  multiplesearchable,
  searchable,
  multiple,
  selected,
  show_field
}) => {
  const [apiData, setApiData] = React.useState(data);
  const [isLoading, setIsLoading] = React.useState(true);
  const localOnChange = (e) => {
    if (multiple) {
      onChange({ id: e.target.id, value: e.target.value })
    } else {
      onChange(e)
    }
  }
  useEffect(() => {
    if (controller) {
      api.getList({ controller: controller }).then((res) => {
        if (res.length > 0) {
          const temp = res.map((item) => {
            return { value: item.id, text: show_field.length < 2 ? item[show_field] : show_field.map(text => item[text] + " ") };
          });
          setApiData(temp);
          setIsLoading(false);
        }
      });
    } else {
      setApiData(data);
      setIsLoading(false);
    }
  }, [data]);

  if (isLoading) {
    return (
      <Grid container style={{ height: 62 }}>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          {id}
          <StyledLinearProgress />
        </Grid>
      </Grid>
    );
  } else if ((!multiple && searchable) && apiData.length > 0) {
    return (
      <StyledAutoComplete
        id={id}
        // size="small"
        onChange={(e, n) => {
          n.target = { id: id, value: n.value }
          onChange(n)
        }}
        options={apiData}
        getOptionLabel={(option) => option.text}
        defaultValue={apiData[0]}
        renderInput={(params) => (
          <CssTextField
            {...params}
            variant="outlined"
            label={label}
            placeholder="Favorites"
          />
        )}
      />
    );
  } else {
    return (
        <Grid container>
          <Grid item xs={12} sm={12} lg={12} md={12}>
            <StyledFormControl
            fullWidth={true}
              style={{ margin: 10, display: 'flex', flexDirection: 'row' }}
              variant="outlined"
            // className={classes.formControl}
            >
            {multiple ? (<StyledInputLabelActive disableAnimation={true} htmlFor={id}>{label}</StyledInputLabelActive>) : (<StyledInputLabel htmlFor={id}>{label}</StyledInputLabel>)}
            <Select
              native
              multiple={multiple}
              onChange={localOnChange}
              value={value}
              id={id}
              fullWidth={true}
              disabled={disabled}
              style={{ borderRadius: 0,border: '1px solid #ced4da' }}
              variant={variant}
              labelId={labelId}
              {...otherOptions}
            >
              {noneExist && <option aria-label="None" value="" />}
              {!searchable  && apiData.length > 0 ? (
                apiData.map((item, key) => {
                  if (multiple) {
                    return (
                      <option key={key} style={{ background: selected.indexOf(item.value) > -1 ? selected_style : 'none' }} value={item.value}>{item.text}</option>
                    )
                  } else {
                    return (
                      <option key={key} value={item.value}>{item.text}</option>
                    )
                  }

                })
              ) : (
                <option value={1}>None</option>
              )}
            </Select>
            {withNext && (
              <Button disabled={disabled || !((value > withNext.min || value == withNext.min) || (value <= withNext.max || value == withNext.max))} onClick={withNext.onClick()} style={{ border: '1px solid #c2c2c1', borderRadius: 0, borderLeft: 'none' }}><NavigateNext /></Button>
            )}
          </StyledFormControl>
        </Grid>
        </Grid>
    );
  }
};

export { InputSelect };
