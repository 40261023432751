import { MessageHandler } from "./../../components/ErrorHandler/ErrorHandler";
import { user_routes } from "../../components/Layout/routes";
import { setRoleStatus, setData } from "../features/user";
import { addView } from "../features/tasks";
import toast, { Toaster } from "react-hot-toast";
import socketIOClient from "socket.io-client";
import sailsIOClient from "sails.io.js";
import i18n from "i18next";
let io;
io = sailsIOClient(socketIOClient);
// io.sails.url = "https://sapi.umzugsritter.at/?token=uihiuhiuh";
io.sails.url = "https://sapi.umzugsritter.at/";

// io.sails.url = "http://localhost:1888/";
io.sails.autoConnect = true;
io.sails.reconnection = true;
io.sails.query = `token=${localStorage.getItem("id_token")}`;
export const UpdateModel = (data) => {
  return {
    type: "UPDATE_MODEL",
    model_name: data.model_name,
    model_type: data.type,
    data: data.data,
  };
};

export const AddNewTask = (data) => ({
  type: "Add_NEW_TASK",
  id: data.id,
  value: data.value,
});

export const completeItem = (data) => ({
  type: "COMPLETED_ITEM",
  itemId: data.id,
  completed: data.completed,
});

/* Used only by actions for sockets */
export const initialItems = (res) => ({
  type: "INITIAL_ITEMS",
  model_name: res.model_name,
  data: res.data,
});
export const updateItem = (res) => ({
  type: "update",
  model_name: res.model_name,
  data: res.data,
});
/***************************************************************************************** */
/* Async Action items using - Sockets													   */
/***************************************************************************************** */
export const loadInitialDataSocket = ({ history }) => {
  if (io.socket.isConnecting()) {
    console.log("Connecting...");
  }
  // console.log(io.socket);
  // if(io.socket.disconnect){
  //     console.log("Disconnected")
  // }

  return (dispatch) => {
    // dispatch(clearAllItems())
    const token = localStorage.getItem("id_token");
    if (token) {
      io.socket.request(
        {
          method: "get",
          url: "/socket/connection",
          headers: {
            authorization: "brear " + localStorage.getItem("id_token"),
          },
        },
        function (resData, jwres) {
          dispatch(
            setData({
              role: resData.token.role,
              status: resData.token.status,
              mobile: resData.token.mobile,
              email: resData.token.email,
              first_name: resData.token.first_name,
              last_name: resData.token.last_name,
              user_id: resData.token.user_id,
            })
          );
          // user_routes
          //   .filter((it) => it.socket)
          //   .map((item) => {
          //     const tmp = {
          //       model_name: item.socket_name,
          //       data: resData[item.socket_name],
          //     };
          //     dispatch(initialItems(tmp));
          //   });
        }
      );
      // socketIOClient.socket.on("profile",(res)=>{
      // 	console.log(res)
      // 	const tmp = {model_name:"profile",data:res,type:"single"}
      // 	dispatch(UpdateModel(tmp))
      // })
      // socketIOClient.socket.on("update", (res) => {
      //   console.log(res);
      //   const tmp = { model_name: res.model, type: "update", date: {} };
      //   if (res.model === "tasks") {
      //     setTimeout(() => {
      //       toast.success("Update Task", {
      //         position: "bottom-right",
      //         autoClose: 5000,
      //         hideProgressBar: false,
      //         closeOnClick: true,
      //         pauseOnHover: true,
      //         draggable: true,
      //         progress: undefined,
      //       });
      //     }, 300);
      //     dispatch(addView({ id: res.data.id, data: res.data }));
      //     // toast.info("Update Task", {
      //     // 	position: "bottom-right",
      //     // 	autoClose: 5000,
      //     // 	hideProgressBar: false,
      //     // 	closeOnClick: true,
      //     // 	pauseOnHover: true,
      //     // 	draggable: true,
      //     // 	progress: undefined,
      //     // });
      //   }
      // });
      // socketIOClient.socket.on("token", (res) => {
      //   console.log(res);
      //   console.log("user socket");
      // });

      // user_routes
      //   .filter((it) => it.socket)
      //   .map((item) => {
      //     socketIOClient.socket.on(item.socket_name, (res) => {
      //       console.log(res);
      //       const tmp = {
      //         model_name: item.socket_name,
      //         data: res.message,
      //         type: item.type,
      //       };
      //       dispatch(UpdateModel(tmp));
      //     });
      //   });
      // // socketIOClient.socket.on('message',(res)=>{
      // //     MessageHandler({type:"info"})
      // //     console.log(res)
      // // })
    }
    io.socket.on("logout", function (msg) {
      if (msg.this_user == true) {
        localStorage.removeItem("id_token");
        dispatch({ type: "SIGN_OUT_SUCCESS" });
        window.location.reload();
      } else {
        toast.success(
          i18n.t(`custom_err.suspend_user_alert`, { user: msg.mobile })
        );
      }
    });
    io.socket.on("update", function (msg) {
      console.log(msg.data);
      dispatch(addView({ id: msg.data.id, data: msg.data }));
      toast.success(`${i18n.t("events.updated")} ${msg.data.name}`);
    });
    io.socket.on("change_status", function (msg) {
      if (msg.this_user == true) {
        localStorage.removeItem("id_token");
        dispatch({ type: "SIGN_OUT_SUCCESS" });
        window.location.reload();
      } else {
        toast.success(
          i18n.t(`custom_err.suspend_user_alert`, { user: msg.mobile })
        );
      }
    });
  };
};

export const markItemCompleteSocket = (socket, id, completedFlag) => {
  return (dispatch) => {
    let postData = {
      id: id,
      completed: completedFlag,
    };
    socket.emit("markItem", postData);
  };
};
